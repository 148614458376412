import React from 'react';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

const CountryNavItem = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
      <Box sx={{ width: 22 }}>
        <img src={t('flag')} alt="" />
      </Box>
    </Box>
  );
};

export default CountryNavItem;
