import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { HideOnScroll } from '../scrolls';

const FingoNavbar = ({ children }) => (
  <HideOnScroll>
    <AppBar elevation={0} position="sticky" color="fingoWhite" sx={{ minHeight: 55, justifyContent: 'center' }}>
      <Toolbar>
        {children}
      </Toolbar>
    </AppBar>
  </HideOnScroll>
);

FingoNavbar.propTypes = {
  children: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default FingoNavbar;
